.btn {
    margin-top: 1.6rem;
    width: 71.25rem;
    height: 7.44rem;
    transition: opacity ease 0.3s;
    &.disable {
        pointer-events: none;
        opacity: 0.3;
    }
}

.chat {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    &_1 {
        margin-top: 2.9rem;
        width: 35.8rem;
    }
    &_2 {
        width: 36.1rem;
        text-align: left;
    }
    &_3 {
        width: 29.9375rem;
        .message:nth-child(1) {
            text-align: left;
        }
        .message:nth-child(2) {
            text-align: left;
        }
    }
    &_4 {
        margin-top: 0;
        text-align: left;
        width: 35.8rem;
    }
}

.images {
    margin-top: 7.5rem;
    display: flex;
    width: 63.125rem;
    flex-wrap: wrap;
    gap: 1.12rem;
    .img {
        margin-top: 0;
    }
    &_2 {
        margin-top: 5.5rem;
    }
}

.img {
    position: relative;
    width: 62.625rem;
    &_1 {
        margin-top: 0;
        height: 29.3125rem;
    }
    &_2 {
        width: 31rem;
        height: 28.5625rem;
        z-index: 10;
        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &_3 {
        width: 31rem;
        height: 28.5625rem;
        z-index: 10;
        img {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
    &_6 {
        width: 33.5rem;
        height: 28.625rem;
    }
    &_7 {
        width: 27.875rem;
        height: 28.625rem;
        img {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
    &_8 {
        width: 100%;
        height: 29.25rem;
        img {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
}

.footer2 {
    display: none;
    &.shown {
        display: flex;
    }
}

.shrimp {
    position: absolute;
    transition: opacity ease .5s;
    &.hide {
        opacity: 0;
        pointer-events: none;
    }
    &_1 {
        top: -3.8rem;
        left: 17.9rem;
        width: 32.21363rem;
        height: 19.34106rem;
        transform: rotate(165deg);
    }
    &_2 {
        top: 3.88rem;
        left: 35.3rem;
        width: 22.05094rem;
        height: 13.23938rem;
        transform: rotate(165deg);
    }
    &_3 {
        top: 9.39rem;
        left: -12rem;
        width: 54.36069rem;
        height: 32.63813rem;
        transform: rotate(-30deg) scale(1, -1);
    }
    &_4 {
        top: -2.7rem;
        left: 43.7rem;
        width: 45.50794rem;
        height: 27.32294rem;
        transform: rotate(-150deg);
    }
    &_5 {
        top: 11.1rem;
        left: 34.5rem;
        width: 39.86463rem;
        height: 23.93469rem;
        transform: rotate(51.577deg) scale(1, -1);
    }
    &_6 {
        top: 17.9rem;
        left: 27.4rem;
        width: 32.21363rem;
        height: 19.34106rem;
        transform: rotate(-165deg);
    }
    &_7 {
        top: -1.3rem;
        left: -1.5rem;
        width: 32.21363rem;
        height: 19.34106rem;
        transform: rotate(-165deg);
    }
    &_8 {
        top: 22.9rem;
        left: -6.5rem;
        width: 32.21363rem;
        height: 19.34106rem;
        transform: rotate(-60deg);
    }
    &_9 {
        top: 15.3rem;
        left: 55.5rem;
        width: 32.21363rem;
        height: 19.34106rem;
        transform: rotate(-135deg) scale(1, -1);
    }
}

.shrimps {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.final {
    margin-top: 7.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4rem;
    padding-bottom: 5.14rem;
    border-radius: 2.5rem 2.5rem 0rem 0rem;
    width: 100%;
    background: var(--main);
    &__screen {
        margin-top: 2.5rem;
        position: relative;
        // width: 82rem;
        // height: 40.8125rem;
        width: 71.25rem;
        height: 35.46206rem;
    }
    &__text {
        margin-top: 2.1rem;
        margin-bottom: 1.3rem;
        width: 36.25rem;
        font-size: 1.75rem;
        color: var(--white);
        font-weight: 500;
        line-height: 126.4%;
    }
    &__info {
        margin-top: 1rem;
        margin-bottom: -1rem;
        width: 36.25rem;
        display: flex;
        span {
            color: var(--white);
            font-size: 1.75rem;
            font-weight: 400;
            line-height: 126.4%;
            width: 27.9375rem;
        }
        picture {
            margin-left: 1rem;
            width: 5.1875rem;
            height: 5.125rem;
        }
    }
    &__img {
        position: relative;
        width: 100%;
        height: 100%;
        picture {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            object-fit: contain;
            transition: opacity ease 0.5s;
            img {
                height: 100%;
                object-fit: contain;
            }
            &:nth-child(2) {
                opacity: 0;
                z-index: 10;
            }
        }
        &.show {
            picture:nth-child(2) {
                opacity: 1;
            }
        }
    }
}

.footer {
    margin-top: 3rem;
    width: 71.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__text {
        color: var(--white);
        width: 32.4375rem;
        font-size: 2rem;
        font-weight: 500;
        line-height: 126.4%;
    }
    &__symbol {
        display: flex;
        width: 6.34813rem;
        height: 4.19725rem;
    }
    &__btn {
        width: 25.25rem;
        height: 5.6rem;
    }
}

.message {
    &+.message {
        margin-top: 0.81rem;
    }
}

.test {
    margin-top: 5rem;
}

.wall {
    padding-top: 7.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;
    align-items: center;
    display: none;
    &.shown {
        display: flex;
    }
    &:last-child {
        margin-bottom: -3.75rem;
    }
}

.wrap {
    padding-top: 10.06rem;
    padding-bottom: 3.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100%;
}

@media only screen and (max-width: 640px) {
    .btn {
        margin-top: 2.5rem;
        width: 21.6875rem;
        line-height: 170%;
        height: 3.75rem;
        text-align: center;
    }
    .chat {
        margin-top: 2.5rem;
        width: 18.44rem;
        &_4 {
            margin-top: 0;
        }
    }
    .final {
        margin-top: 5rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        border-radius: 1.25rem 1.25rem 0rem 0rem;
        &__screen {
            margin-top: 2rem;
            width: 21.4375rem;
            height: 33.6875rem;
        }
        &__text {
            margin-top: 2rem;
            margin-bottom: -0.8rem;
            width: 21.4375rem;
            font-size: 1.5rem;
        }
        &__info {
            margin: 0;
            width: 21.5rem;
            span {
                font-size: 1.5rem;
                font-weight: 500;
                width: 14.1875rem;
            }
            picture {
                margin-top: auto;
                margin-left: auto;
                width: 5.1875rem;
                height: auto;
            }
        }
    }
    .footer {
        margin-top: 2.31rem;
        width: 100%;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        &__text {
            width: 20.125rem;
            font-size: 1.5rem;
        }
        &__symbol {
            display: none;
        }
        &__btn {
            margin-top: 1.13rem;
            width: 21.6875rem;
            height: 3.75rem;
        }
    }
    .images {
        margin-top: 3.5rem;
        width: 21.502rem;
        gap: 0.40975rem;
        &_2 {
            margin-top: 1.5rem;
        }
    }
    .img {
        width: 21.4375rem;
        margin-top: 0;
        &_1 {
            margin-top: 0;
            height: 10.0625rem;
        }
        &_2 {
            margin-top: 0;
            width: 10.52775rem;
            height: 9.75rem;
            z-index: 10;
        }
        &_3 {
            margin-top: 0;
            width: 10.53rem;
            height: 9.75rem;
        }
        &_4 {
            margin-top: 0;
            width: 21.4375rem;
        }
        &_6 {
            width: 11.4905rem;
            height: 9.81838rem;
        }
        &_7 {
            width: 9.56113rem;
            height: 9.81838rem;
        }
        &_8 {
            width: 100%;
            height: 10.0625rem;
        }
    }
    .shrimp {
        position: absolute;
        transition: opacity ease .5s;
        &.hide {
            opacity: 0;
            pointer-events: none;
        }
        &_1 {
            top: 0.13rem;
            left: -1.6rem;
            width: 18.51456rem;
            height: 11.11613rem;
            transform: rotate(-150deg);
        }
        &_2 {
            top: -3.7rem;
            left: 6.19rem;
            width: 24.77438rem;
            height: 14.8745rem;
            transform: rotate(135deg);
        }
        &_3 {
            top: 25.1rem;
            left: 0.94rem;
            width: 24.77438rem;
            height: 14.8745rem;
            transform: rotate(135deg) scale(1, -1);
        }
        &_4 {
            top: 14.8rem;
            left: -5.3rem;
            width: 24.77438rem;
            height: 14.8745rem;
            transform: rotate(135deg);
        }
        &_5 {
            top: 17.7rem;
            left: 8.18rem;
            width: 19.754rem;
            height: 11.86031rem;
            transform: rotate(-135deg) scale(1, -1);
        }
        &_6 {
            top: 9.2rem;
            left: 5.43rem;
            width: 24.77438rem;
            height: 14.8745rem;
            transform: rotate(-150deg);
        }
        &_7 {
            top: 3.78rem;
            left: -3.8rem;
            width: 19.21163rem;
            height: 11.53463rem;
            transform: rotate(30deg);
        }
        &_8 {
            top: 18.5rem;
            left: 2.35rem;
            width: 19.21163rem;
            height: 11.53463rem;
            transform: rotate(30deg);
        }
        &_9 {
            top: 10.7rem;
            left: -2.4rem;
            width: 13.84219rem;
            height: 8.31088rem;
            transform: rotate(30deg) scale(1, -1);
        }
    }
    .test {
        margin-top: 1.75rem;
    }
    .wall {
        padding-top: 3.75rem;
        &:last-child {
            padding-bottom: 0;
            margin-bottom: -2.5rem;
        }
    }
    .wrap {
        padding-top: 5.13rem;
        padding-bottom: 2.5rem;
    }
}