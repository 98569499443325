.btn {
    margin-top: 5rem;
    width: 71.25rem;
    height: 7.44rem;
}

.chat {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &_1 {
        width: 35.8rem;
    }
    &_2 {
        width: 36.1rem;
    }
    &_3 {
        width: 36.1rem;
    }
    &_4 {
        width: 24.1rem;
    }
}

.images {
    display: flex;
    width: 62.5rem;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1.13rem;
}

.img {
    position: relative;
    &_1 {
        width: 100%;
        height: 29.375rem;
    }
    &_2 {
        width: 25.625rem;
        height: 29.375rem;
        img {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
    &_3 {
        width: 35.6875rem;
        height: 29.375rem;
    }
    &_4 {
        width: 62.53125rem;
        height: 29.375rem;
    }
    &_5 {
        width: 62.46481rem;
        height: 29.375rem;
    }
    &_6 {
        width: 62.53125rem;
        height: 29.40625rem;
    }
}
.footer{
    display: none;
    &.shown{
        display: flex;
    }
}
.message {
    width: 100%;
    &+.message {
        margin-top: 0.81rem;
    }
}

.test {
    margin-top: 3rem;
}

.wall {
    padding-top: 7.5rem;
    position: relative;
    width: 100%;
    flex-direction: column;
    align-items: center;
    display: none;
    &.shown {
        display: flex;
    }
    &:last-child {
        padding-bottom: 0;
    }
}

.wrap {
    padding-top: 10.06rem;
    padding-bottom: 3.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100%;
}

@media only screen and (max-width: 640px) {
    .btn {
        margin-top: 2.5rem;
        width: 21.6875rem;
        height: 3.75rem;
    }
    .chat {
        margin-top: 2.5rem;
        width: 18.44rem;
        &_4 {
            width: 15.3rem;
        }
    }
    .images {
        width: 21.502rem;
        gap: 0.38706rem;
    }
    .img {
        &_1 {
            height: 10.10594rem;
        }
        &_2 {
            width: 8.81581rem;
            height: 10rem;
        }
        &_3 {
            width: 12.25rem;
            height: 10rem;
        }
        &_4 {
            width: 21.4375rem;
            height: 10.0625rem;
        }
        &_5 {
            width: 21.4375rem;
            height: 10.0625rem;
        }
        &_6 {
            width: 21.4375rem;
            height: 10.0625rem;
        }
    }
    .wall {
        padding-top: 3.75rem;
        &:last-child {
            padding-bottom: 0;
        }
    }
    .wrap {
        padding-top: 5.13rem;
        padding-bottom: 2.5rem;
    }
}