.wrap{
	padding: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--black);
	color: var(--white);
	width: 100%;
	height: 100%;
	font-weight: 400;
	font-size: 2.5rem;
	border-radius: 6.25rem;
	cursor: pointer;
	transition-property: background, border, color;
	transition-timing-function: ease, ease, ease;
	transition-duration: 0.3s, 0.3s, 0.3s;
	border: none;
	&:hover{
		background: var(--white);
		color: var(--black);
		svg path{
			fill: var(--black);
		}
	}
	&_theme_on_white{
		&:hover{
			background: var(--main);
			color: var(--white);
		}
	}
	&_theme_border{
		background: var(--white);
		color: var(--black);
		border: max(2px, 0.13rem) solid var(--black);
		&:hover{
			border-color: var(--main);
			color: var(--main);
		}
	}
	&_theme_border_white{
		background: transparent;
		color: var(--white);
		border: max(2px, 0.13rem) solid var(--white);
		&:hover{
			background: transparent;
			border-color: var(--black);
			color: var(--black);
		}
	}
	&_size_small{
		font-size: 1.78569rem;
		padding: 1.42856rem 3.5675rem;
	}
	&_size_medium{
		font-size: 2rem;
		padding: 1.42856rem 3.5675rem;
	}
	svg{
		margin-left: 1rem;
		width: 2.3125rem;
		transform: translateZ(0);
		path{
			
		transition: all ease 0.3s;
		}
	}
}
	
@media only screen and (max-width: 640px) {
	.wrap{
		padding: 1.42856rem 3.5675rem;
		font-size: 1.5rem;
		border-radius: 5.4405rem;
		&:hover{
			background: var(--black);
			color: var(--white);
			svg path{
				fill: var(--white)
			}
		}
		&_theme_on_white{
			&:hover{
				background: var(--black);
				color: var(--white);
			}
		}
		&_theme_border{
			border: max(1px, 0.06rem) solid var(--black);
			&:hover{
				
				background: var(--white);
				border-color: var(--black);
				color: var(--black);
			}
		}
		&_theme_border_white{
			border: max(1px, 0.06rem) solid var(--white);
			&:hover{
				background: transparent;
				border-color: var(--white);
				color: var(--white);
			}
		}
		&_size_small{
			font-size: 1.5rem;
			padding: 1.42856rem 3.5675rem;
		}
		&_size_medium{
			font-size: 1.5rem;
			padding: 1.42856rem 3.5675rem;
		}
		svg{
			margin-left: 0.71rem;
			width: 1.25rem;
		}
	}
		
}