.wrap {
    display: flex;
    align-items: flex-end;
    &_pos_right {
        margin-left: 5.91rem;
        flex-direction: row-reverse;
        .text {
            margin-right: 1.25rem;
            margin-left: 0;
            border-radius: 1rem 1rem 0rem 1rem;
        }
    }
    &_theme_viol {
        .text {
            background: #E0DDFB;
        }
    }
    &_width_auto {
        &.wrap_pos_right {
            margin-left: 0;
        }
        .text {
            flex-shrink: unset;
            max-width: 100%;
            width: 100%;
        }
    }
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.375rem;
    height: 4.375rem;
}

.text {
    margin-left: 1.25rem;
    padding: 1.25rem;
    max-width: 23.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem 1rem 1rem 0;
    background: var(--white);
    white-space: pre-wrap;
    &>*{
        width: 100%;
    }
}

@media only screen and (max-width: 640px) {
    .icon {
        width: 2.5rem;
        height: 2.5rem;
    }
    .text {
        margin-left: 0.63rem;
        padding: 0.625rem 0.9375rem;
        max-width: 15rem;
        white-space: unset;
    }
    .wrap {
        &_pos_right {
            margin-left: 3.12rem;
            flex-direction: row-reverse;
            .text {
                margin-right: 0.63rem;
                margin-left: 0;
            }
        }
    }
}