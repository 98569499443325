.btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: min(57.1rem, 101.556vh);
    top: min(32.8rem, 58.333vh);
    font-size: min(4.625rem, 8.222vh);
    width: min(20.125rem, 35.778vh);
    height: min(20.125rem, 35.778vh);
    border-radius: 50%;
    background: #7266E5;
    font-weight: 700;
    line-height: 100%;
    z-index: 100;
    object-fit: contain;
    cursor: pointer;
    &:hover {
        .btn__wing {
            transform: translate(0, 0);
        }
    }
    &.push {
        pointer-events: none;
        .btn__inner {
            border: min(0.88rem, 1.556vh) solid #000;
            width: 100%;
            height: 100%;
        }
        .btn__wing {
            transform: translate(5%, 5%);
            transition: transform ease-out 0.2s;
        }
    }
    &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: var(--white);
        border: min(0.38rem, 0.667vh) solid #000;
        width: min(16.625rem, 29.556vh);
        height: min(16.625rem, 29.556vh);
        transition: all ease-out 0.2s;
    }
    &__wing {
        position: absolute;
        top: min(10.06rem, 17.889vh);
        left: min(14.5rem, 25.778vh);
        width: min(19.93563rem, 35.441vh);
        height: min(23.59131rem, 41.94vh);
        pointer-events: none;
        // transform: translate(5%, 5%);
        transform: translate(0, 0);
        transition: transform ease-out 0.5s;
    }
}

.game {
    position: relative;
    width: 100%;
    height: 100%;
    transition: opacity ease 0.5s;
    opacity: 0;
    z-index: 11;
    canvas {
        width: auto !important;
        height: 100% !important;
        object-fit: contain;
    }
    &.init {
        opacity: 1;
    }
}

.counter {
    position: fixed;
    padding: 0 1.44rem 0.31rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    top: 1.12rem;
    right: 1.25rem;
    font-size: 2.5rem;
    line-height: 120%;
    color: var(--black);
    background: rgba(255, 255, 255, 0.80);
    font-weight: bold;
    border-radius: 6.25rem;
}

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background: rgba(83, 72, 177, 0.80);
    opacity: 0;
    pointer-events: none;
    transition: ease 0.5s;
    &.active {
        opacity: 1;
        pointer-events: auto;
    }
    &__body {
        width: 38.25rem;
        display: inline-flex;
        padding: 6.25rem 7.5rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        border-radius: 2.5rem;
        background: var(--white);
    }
    &__btns {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    &__title {
        width: 30.625rem;
        text-align: center;
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 120%;
    }
    &__text {
        width: 100%;
        flex-direction: column;
        align-items: center;
        text-align: center;
        span {
            display: inline-block;
            font-weight: bold;
        }
    }
    &__btn {
        width: 100%;
        border: none;
        background: none;
        &+.popup__btn {
            margin-top: 0.75rem;
        }
    }
}

.wrap {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: var(--main);
    overflow: hidden;
    transition: opacity ease 0.5s;
    opacity: 0;
    z-index: 10;
    &.init {
        opacity: 1;
    }
}

.wrapper {
    position: relative;
    width: auto;
    height: 100%;
    max-width: 90rem;
    max-height: 56.25rem;
    overflow: hidden;
}

@media only screen and (max-width: 640px) {
    .btn {
        left: 1.94rem;
        top: 35.44rem;
        font-size: 1.25rem;
        width: 19.5625rem;
        height: 5.125rem;
        border-radius: 62.5rem;
        &:hover {
            .btn__wing {
                transform: translate(0, 0);
            }
        }
        &.push {
            pointer-events: none;
            .btn__inner {
                border: 0.25rem solid #000;
                border-radius: 62.5rem;
            }
            .btn__wing {
                transform: translate(5%, 5%);
                transition: transform ease-out 0.2s;
            }
        }
        &__inner {
            border-radius: 5.4405rem;
            border: 0.19rem solid #000;
            width: 17.9375rem;
            height: 3.75rem;
            transition: all ease-out 0.2s;
        }
        &__wing {
            position: absolute;
            top: 1.44rem;
            left: 15.5rem;
            width: 8.71444rem;
            height: 10.3125rem;
            pointer-events: none;
            // transform: translate(5%, 5%);
            transform: translate(0, 0);
            transition: transform ease-out 0.5s;
        }
    }
    .popup {
        &__body {
            width: 20.5rem;
            padding: 4.375rem 1.25rem;
            gap: 1.5rem;
        }
        &__icon {
            margin: 1.25rem 0;
            width: 3.75rem;
            height: 3.5625rem;
        }
        &__title {
            width: 100%;
            font-size: 1.75rem;
        }
        &__text {
            p:nth-child(3) {
                width: 14.5rem;
            }
        }
        &__btn {
            width: 100%;
            height: 3.75rem;
            &+.popup__btn {
                margin-top: 0.63rem;
            }
        }
    }
    .wrap {
        max-width: 100%;
        max-height: unset;
        height: calc(var(--vhd, 1vh)*100 );
        overflow: auto;
        background: var(--main);
        &::after {
            display: none;
        }
        &::before {
            display: none;
        }
    }
    .wrapper {
        width: 100%;
        min-height: 42.5rem;
    }
    .game {
        position: relative;
        canvas {
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: auto !important;
            object-position: bottom;
            object-fit: cover;
        }
        &::after {
            display: none;
        }
        &::before {
            display: none;
        }
    }
    .counter {
        padding: 0 1rem;
        top: 1rem;
        right: 1rem;
        height: 2.19rem;
        font-size: 1.75rem;
    }
}