.answers {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.answer {
    position: relative;
    padding: 1.25rem 2.5rem;
    width: 100%;
    border-radius: 1.25rem;
    background: var(--white);
    text-align: center;
    cursor: pointer;
    &+.answer {
        margin-top: 1rem;
    }
    &::after {
        position: absolute;
        content: '';
        box-sizing: border-box;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        pointer-events: none;
        opacity: 0;
        transition: opacity ease 0.3s;
        border: 0.19rem solid var(--black);
        z-index: 10;
    }
    &:hover {
        &::after {
            opacity: 1;
        }
    }
}

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4.38rem 6.03rem;
    width: 100%;
}

.popup {
    padding: 4.38rem 8.44rem 3.19rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    color: var(--white);
    background: var(--main);
    transition: opacity ease 0.5s;
    &__body{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    &__img {
        position: absolute;
        &_0 {
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
            width: 38.19rem;
        }
        &_1 {
            top: 0;
            left: 0;
            width: 100%;
        }
        &_2 {
            right: 0;
            top: 9.69rem;
            width: 22.38rem;
        }
    }
    &__title {
        color: var(--white);
        text-align: center;
        font-size: 2.5rem;
        font-weight: 500;
        width: 35rem;
        line-height: 120%;
    }
    &__text {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 1.25rem;
        text-align: center;
        p+p {
            margin-top: 1.25rem;
        }
    }
    &__restart {
        margin-top: auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2.21469rem;
        font-weight: 500;
        line-height: 2.12213rem;
        letter-spacing: 0.33219rem;
        text-transform: uppercase;
        white-space: nowrap;
        opacity: 0.4;
        cursor: pointer;
        transition: opacity ease 0.3s;
        svg {
            margin-left: 1.5rem;
            width: 3.1rem;
        }
        &:hover {
            opacity: 1;
        }
    }
    &.active {
        opacity: 1;
        pointer-events: auto;
    }
    &.fail {
        background: var(--black);
    }
}

.wrap {
    position: relative;
    display: flex;
    width: 48.1875rem;
    min-height: 37.5rem;
    background: var(--main);
    border-radius: 2.5rem;
    overflow: hidden;
    p:nth-child(2) {
        width: 28.25rem;
    }
    &_use_1 {
        p:nth-child(1) {
            width: 24.0625rem;
        }
    }
    &_use_2 {
        p:nth-child(1) {
            width: 27.25rem;
        }
    }
    &_use_3 {
        p:nth-child(1) {
            width: 28.375rem;
        }
    }
    &_use_4 {
        p:nth-child(1) {
            width: 27.25rem;
        }
    }
    &_use_5 {
        p:nth-child(1) {
            width: 27.25rem;
        }
    }
    &_use_6 {
        p:nth-child(1) {
            width: 27.25rem;
        }
    }
    &_use_7 {
        p:nth-child(1) {
            width: 27.25rem;
        }
    }
    &_use_8 {
        p:nth-child(1) {
            width: 25.875rem;
        }
    }
}

.question {
    color: var(--white);
    text-align: center;
    font-size: 2.5rem;
    font-weight: 500;
    width: 35rem;
    line-height: 120%;
}

@media only screen and (max-width: 640px) {
    .answers {
        margin-top: 1.25rem;
    }
    .answer {
        padding: 0.62rem 2.31rem;
        width: 100%;
        border-radius: 0.75rem;
        font-size: 0.875rem;
        line-height: 126.4%;
        font-weight: 300;
        &+.answer {
            margin-top: 0.5rem;
        }
        &::after {
            display: none;
        }
    }
    .body {
        padding: 2rem 1.5rem 2.5rem;
    }
    .popup {
        padding: 2rem 1.75rem 1.69rem;
        &__img {
            &_0 {
                width: 19.46rem;
            }
            &_1 {
                top: 0;
                left: 0;
                width: 100%;
            }
            &_2 {
                right: 0;
                top: 6rem;
                width: 14.4375rem;
            }
        }
        &__title {
            font-size: 1.5rem;
            width: 100%;
            line-height: 126.4%;
        }
        &__text {
            font-size: 0.875rem;
            margin-top: 1rem;
            line-height: 126.4%;
            font-weight: 300;
            p+p {
                margin-top: 0.55rem;
            }
        }
        &__restart {
            font-size: 1.125rem;
            line-height: 1.25rem;
            letter-spacing: 0.16875rem;
            svg {
                margin-left: 1rem;
                width: 1.4375rem;
            }
            &:hover {
                opacity: 0.4;
            }
        }
    }
    .wrap {
        width: 21.4375rem;
        min-height: 22.125rem;
        border-radius: 1.25rem;
        p:nth-child(1) {
            width: 16.875rem;
        }
        p:nth-child(2) {
            width: 16.875rem;
        }
    }
    .question {
        font-size: 1.5rem;
        width: 100%;
        line-height: 126.4%;
    }
}