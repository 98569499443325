.wrap {
    width: 100%;
    min-height: 100%;
    overflow: hidden;
}

@media only screen and (max-width: 640px) {
    .wrap {
        width: 100%;
        min-height: 100%;
        overflow: unset;
    }
}