.game {
    position: relative;
    width: 100%;
    height: 100%;
    transition: opacity ease 0.5s;
    opacity: 0;
    z-index: 11;
    &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100vh;
        bottom: 0;
        left: calc(100% - 7.2625rem);
        background: #796cf0;
        z-index: -1;
    }
    &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100vh;
        bottom: 0;
        left: calc(-100% + 7.3125rem);
        background: #796cf0;
        z-index: -1;
    }
    canvas {
        width: auto !important;
        height: 100% !important;
        object-fit: contain;
    }
    &.init {
        opacity: 1;
    }
}

.counter {
    position: fixed;
    padding: 0 1.44rem 0.31rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    top: 1.12rem;
    right: 1.25rem;
    font-size: 2.5rem;
    line-height: 120%;
    color: var(--black);
    background: rgba(255, 255, 255, 0.80);
    font-weight: bold;
    border-radius: 6.25rem;
}

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background: rgba(83, 72, 177, 0.80);
    opacity: 0;
    pointer-events: none;
    transition: ease 0.5s;
    &.active {
        opacity: 1;
        pointer-events: auto;
    }
    &__body {
        width: 38.25rem;
        display: inline-flex;
        padding: 6.25rem 7.5rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        border-radius: 2.5rem;
        background: var(--white);
    }
    &__icon {
        margin: 1.5rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10.375rem;
        height: 2.75rem;
        &_mobile {
            width: 4rem;
            height: auto;
        }
    }
    &__btns {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    &__title {
        width: 23.125rem;
        text-align: center;
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 120%;
    }
    &__text {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        span {
            display: inline-block;
            font-weight: bold;
        }
        p:nth-child(3) {
            width: 18.9rem;
        }
    }
    &__btn {
        width: 100%;
        border: none;
        background: none;
        &+.popup__btn {
            margin-top: 0.75rem;
        }
    }
}

.wrap {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background: var(--white);
    transition: opacity ease 0.5s;
    opacity: 0;
    z-index: 10;
    &.init {
        opacity: 1;
    }
    &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 50%;
        top: 0;
        left: 0;
        background: #8577f4;
        z-index: -1;
    }
    &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 50%;
        bottom: 0;
        left: 0;
        background: #afa6f7;
        z-index: -1;
    }
}

.wrapper {
    position: relative;
    width: auto;
    height: 100%;
    max-width: 90rem;
    max-height: 56.25rem;
}

@media only screen and (max-width: 640px) {
    .popup {
        &__body {
            width: 20.5rem;
            padding: 4.375rem 1.25rem;
            gap: 1.5rem;
        }
        &__icon {
            margin: 1.25rem 0;
            width: 3.75rem;
            height: 3.5625rem;
        }
        &__title {
            width: 100%;
            font-size: 1.75rem;
        }
        &__text {
            p:nth-child(3) {
                width: 14.5rem;
            }
        }
        &__btn {
            width: 100%;
            height: 3.75rem;
            &+.popup__btn {
                margin-top: 0.63rem;
            }
        }
    }
    .wrap {
        max-width: 100%;
        max-height: unset;
        height: calc(var(--vhd, 1vh)*100 );
        background: var(--main);
        &::after {
            display: none;
        }
        &::before {
            display: none;
        }
    }
    .wrapper {
        width: 100%;
    }
    .game {
        position: relative;
        canvas {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100% !important;
            height: auto !important;
            object-position: bottom;
            object-fit: cover;
        }
        &::after {
            display: none;
        }
        &::before {
            display: none;
        }
    }
    .counter {
        padding: 0 1.25rem;
        top: 1rem;
        right: 1rem;
        height: 2.375rem;
        font-size: 1.75rem;
    }
}