.wrap {
	margin-top: -0.1rem;
    padding: 0.56rem 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 4.4375rem;
    z-index: 10000;
	&_invert{
		background: var( --main);
	}
}

.logo {
    display: flex;
    width: 18.8125rem;
    height: 1.6875rem;
}

.loc {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 400;
    img {
        margin-right: 0.69rem;
        width: 1.9375rem;
        height: 2.8125rem;
    }
}
.create{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.25rem;
    span{
        padding-bottom: 0.3rem;
        height: 1.625rem;
        display: flex;
        align-items: center;
        font-size: 1.25rem;
    }
    img{
        margin-left: 0.56rem;
        width: 9.1875rem;

    }
}
@media only screen and (max-width: 640px) {
    .logo {
        width: 9.875rem;
        height: 0.875rem;
    }
    .loc {
        font-size: 1rem;
        img {
            display: none;
        }
    }
    .wrap {
        top: 0.5rem;
        padding: 0 1rem;
        height: 2.375rem;
    }
    .create{
        display: none;
    }
}