:root {
    --white: #ffffff;
    --black: #000000;
    --main: #8377F1;
}

html, body, #root {
    width: 100%;
    height: 100%;
}

html {
    font-size: 1.11vw;
    // font-size: min(1.111vw, 16px);
    -webkit-font-smoothing: antialiased;
}

body {
    position: relative;
    font-family: 'Aeroport';
    font-size: 1.25rem;
    line-height: 126.4%;
    font-weight: 300;
    color: var(--black);
    transition: opacity ease 0.5s;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    flex-shrink: 0;
}

sub, sup {
    line-height: 0;
    font-size: 0.7em;
}

#root {
    position: relative;
    z-index: 10;
}

.wrap {
    position: relative;
    width: 100%;
    height: 100%;
}

a {
    text-decoration: none;
    transform: translateZ(0);
}

img, svg, picture, object {
    height: auto;
    width: 100%;
    flex-shrink: 0;
}

picture {
    display: flex;
    align-items: flex-start;
}

span, p, input, button, a, ul {
    font: inherit;
    color: inherit;
}

p+p {
    margin-top: 1.25rem;
}

[data-animate] {
    transition: transform ease 0.7s, opacity ease 0.7s;
    --length: 2rem;
}

[data-animate='fade-chat'], [data-animate-mobile='fade-chat'] {
    &>* {
        transition: transform ease 0.7s, opacity ease 0.7s;
        --length: 2rem;
        transform: translateY(var(--length));
        opacity: 0;
    }
}

[data-animate='fade-chat'].animate, [data-animate-mobile='fade-chat'].animate {
    &>* {
        transform: translateY(0);
        opacity: 1;
    }
    &>*:nth-child(2) {
        transition-delay: 0.2s;
    }
    &>*:nth-child(3) {
        transition-delay: 0.4s;
    }
    &>*:nth-child(4) {
        transition-delay: 0.6s;
    }
    &>*:nth-child(5) {
        transition-delay: 0.8s;
    }
    &>*:nth-child(6) {
        transition-delay: 1.0s;
    }
    &>*:nth-child(7) {
        transition-delay: 1.2s;
    }
    &>*:nth-child(8) {
        transition-delay: 1.4s;
    }
    &>*:nth-child(9) {
        transition-delay: 1.6s;
    }
    &>*:nth-child(10) {
        transition-delay: 1.8s;
    }
}

[data-animate='fade-in-up'], [data-animate-mobile='fade-in-up'] {
    transform: translateY(var(--length));
    opacity: 0;
}

[data-animate='fade-in-up'].animate:not(.trigger-disable), [data-animate-mobile='fade-in-up'].animate:not(.trigger-disable) {
    transform: translateY(0);
    opacity: 1;
}

[data-animate='fade-in-left'], [data-animate-mobile='fade-in-left'] {
    transform: translateX(var(--length));
    opacity: 0;
}

[data-animate='fade-in-left'].animate, [data-animate-mobile='fade-in-left'].animate {
    transform: translateX(0);
    opacity: 1;
}

[data-animate='fade-in-right'] {
    transform: translateX(var(--length));
    opacity: 0;
}

[data-animate='fade-in-right'].animate {
    transform: translateX(0);
    opacity: 1;
}

[data-animate='rotate-in'] {
    transform: translate(0, 50%) rotateX(90deg);
}

[data-animate='rotate-in'].animate {
    transform: translate(0, 0) rotateX(0);
    transition: all ease 0.3s;
}

@media only screen and (max-width: 640px) {
    html {
        font-size: 4.267vw;
    }
    body {
        font-size: 1rem;
        line-height: 126.4%;
    }
    * {
        cursor: unset !important;
    }
    .container {
        padding: 0 1.25rem;
    }
    .wrap {
        overflow: hidden;
    }
    p+p {
        margin-top: 1.55rem;
    }
    [data-animate-mobile='none'] {
        transform: none !important;
        transition: none !important;
    }
    [data-animate-mobile='none'].animate {
        transform: none !important;
        transition: none !important;
    }
}