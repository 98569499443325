.btn {
    margin-top: 3.65rem;
    width: 32.9375rem;
    height: 7.44rem;
}

.info {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    width: 38.875rem;
}

.img {
    position: absolute;
    top: 0.9rem;
    left: 5.4rem;
    width: 33.375rem;
    height: 43rem;
    
}

.screen {
    display: flex;
    padding: 2.2rem 6.5rem 1.5rem;
    margin: auto;
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 90rem;
    max-height: 48rem;
    color: var(--white);
}

.symbol {
    margin-top: 1.7rem;
    margin-left: 25rem;
    width: 18.18538rem;
}

.text {
    margin-top: 1.45rem;
    width: 33.6875rem;
    font-size: 1.75rem;
    font-weight: 300;
    line-height: 126.4%;
}

.title {
    font-size: 4.625rem;
    line-height: 100%;
    font-weight: 500;
}

.wrap {
    padding: 4.2rem 0 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    background: var(--main);
}

@media only screen and (max-width: 640px) {
    .btn {
        margin-top: 1.8rem;
        width: 100%;
        height: 3.75rem;
    }
    .info {
        margin-top: 1.25rem;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-shrink: unset;
        align-items: flex-start;
    }
    .img {
        position: static;
        margin: 0 auto;
        width: 13.75rem;
        height: 17.75rem;
        // height: 42vh;
        // flex-grow: 2;
        // flex-shrink: unset;
        // picture, img{
        //     height: 100%;
        //     object-fit: contain;
        // }
    }
    .screen {
        margin: 0;
        padding: 4.2rem 1rem 1rem;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        height: auto;
        max-width: unset;
        max-height: unset;
        color: var(--white);
    }
    .symbol {
        display: none;
    }
    .text {
        margin-bottom: auto;
        margin-top: 0.75rem;
        width: 100%;
        font-size: 1.25rem;
    }
    .title {
        width: 15rem;
        font-size: 1.75rem;
        line-height: 120%;
    }
    .wrap {
        padding: 0;
        align-items: stretch;
        min-height: 100%;
    }
}