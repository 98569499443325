.btn {
    margin-top: 5rem;
    width: 71.25rem;
    height: 7.44rem;
}

.chat {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    &_1 {
        width: 35.8rem;
        text-align: left;
    }
    &_2 {
        width: 36.1rem;
    }
    &_3 {
        width: 35.8rem;
    }
    &_4 {
        text-align: left;
        width: 35.8rem;
    }
}

.images {
    margin-top: 7.5rem;
    display: flex;
    width: 62.5rem;
    flex-wrap: wrap;
    gap: 1.13rem;
}

.img {
    position: relative;
    width: 62.625rem;
    &_1 {
        margin-top: 0;
        height: 29.375rem;
    }
    &_2 {
        margin-top: 0;
        width: 35.5625rem;
        height: 28.94rem;
        z-index: 10;
        img {
            position: absolute;
            width: 36.3125rem;
            top: 0;
            left: 0;
        }
    }
    &_3 {
        margin-top: 0;
        width: 25.5rem;
        height: 28.94rem;
        z-index: 10;
        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &_4 {
        margin-top: 0;
        width: 100%;
        height: 29.4375rem;
        img {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
    &_7 {
        margin-top: 5.5rem;
    }
}
.footer{
    display: none;
    &.shown{
        display: flex;
    }
}
.message {
    &+.message {
        margin-top: 0.81rem;
    }
}

.test {
    margin-top: 3rem;
}

.wall {
    padding-top: 7.5rem;
    position: relative;
    width: 100%;
    flex-direction: column;
    align-items: center;
    display: none;
    &.shown {
        display: flex;
    }
}

.wrap {
    padding-top: 10.06rem;
    padding-bottom: 3.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100%;
}

@media only screen and (max-width: 640px) {
    .btn {
        margin-top: 2.5rem;
        width: 21.6875rem;
        line-height: 170%;
        height: 5rem;
        text-align: center;
    }
    .chat {
        margin-top: 2.5rem;
        width: 18.44rem;
    }
    .images {
        margin-top: 3.75rem;
        width: 21.502rem;
        gap: 0.5rem;
    }
    .img {
        width: 21.4375rem;
        &_1 {
            margin-top: 0;
            height: 10.125rem;
        }
        &_2 {
            margin-top: 0;
            width: 12.125rem;
            height: 9.875rem;
            z-index: 10;
            img {
                width: 12.47288rem;
            }
        }
        &_3 {
            margin-top: 0;
            width: 8.65894rem;
            height: 9.875rem;
        }
        &_4 {
            margin-top: 0;
            width: 100%;
            height: 10.1625rem;
        }
        &_7 {
            margin-top: 2.5rem;
        }
    }
    .wall {
        padding-top: 3.75rem;
        &:last-child {
            padding-bottom: 0;
        }
    }
    .wrap {
        padding-top: 5.13rem;
        padding-bottom: 2.5rem;
    }
}