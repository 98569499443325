.wrap {
    background: var(--green);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 46.88rem;
    width: 37.88rem;
    border-radius: 1.88rem;
    color: var(--white);
}

.title {
    font-weight: 600;
    font-size: 2.38rem;
    line-height: 3.00rem;
}

.movement {
    margin-top: 1.75rem;
    width: 10.38rem;
    display: flex;
}

.movement_mobile {
    margin-top: 1.8rem;
    margin-left: 4rem;
    &+.text {
        margin-top: 1rem;
    }
}

.text {
    margin-top: 1.88rem;
    width: 21.25rem;
    text-align: center;
    font-size: 1.25rem;
    line-height: normal;
}

.btn {
    margin-top: 3.00rem;
    width: 17.81rem;
    height: 3.81rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5rem;
    background: var(--white);
    color: var(--green);
    font-size: 1.25rem;
    font-weight: 500;
    cursor: pointer;
    transition: box-shadow ease 0.2s, color ease 0.2s;
    transform: translateZ(0);
    &:hover {
        box-shadow: -0.13rem 0.13rem 0rem 0rem #2C636B;
        color: #2C636B;
    }
}

// @media screen and (max-width: 640px) and (max-aspect-ratio: 5/7) {
@media screen and (max-aspect-ratio: 5/7) {
    .wrap {
        height: 34.38rem;
        width: 20.13rem;
    }
    .title {
        line-height: 2.25rem;
        font-size: 1.75rem;
    }
    .movement {
        margin-top: 1.23rem;
        width: 10rem;
        &+.text {
            margin-top: 1.13rem;
        }
    }
    .movement_mobile {
        margin-top: 1.23rem;
        margin-left: 1.3rem;
        width: 3.25rem;
        &+.text {
            margin-top: 1.13rem;
        }
    }
    .text {
        margin-top: 1.81rem;
        width: 16.63rem;
        font-size: 1.00rem;
    }
    .btn {
        margin-top: 1.75rem;
        width: 15.25rem;
        height: 3.5rem;
        font-size: 1.00rem;
    }
}