

@font-face {
    font-family: 'Aeroport';
    src: url('Aeroport.woff2');
    src: local('Aeroport'),
        url('Aeroport.woff2') format('woff2'),
        url('Aeroport.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'Aeroport';
    src: url('Aeroport-Medium.woff2');
    src: local('Aeroport Medium'), local('Aeroport-Medium'),
        url('Aeroport-Medium.woff2') format('woff2'),
        url('Aeroport-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Aeroport';
    src: url('Aeroport-Bold.woff2');
    src: local('Aeroport Bold'), local('Aeroport-Bold'),
        url('Aeroport-Bold.woff2') format('woff2'),
        url('Aeroport-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Aeroport';
    src: url('Aeroport-Light.woff2');
    src: local('Aeroport Light'), local('Aeroport-Light'),
        url('Aeroport-Light.woff2') format('woff2'),
        url('Aeroport-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

