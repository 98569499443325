.btn {
    margin-top: 5rem;
    width: 71.25rem;
    height: 7.44rem;
}

.chat {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.img {
    display: flex;
    margin-top: 5rem;
    &_1 {
        width: 50rem;
        height: 33.75rem;
    }
    &_2 {
        width: 52.1875rem;
        height: 22.375rem;
    }
}

.message {
    width: 100%;
    &+.message {
        margin-top: 0.81rem;
    }
    &:last-child {
        text-align: right;
    }
}

.text {
    font-size: 1.75rem;
    line-height: 2.212rem;
    font-weight: 500;
    color: var(--white);
    max-width: 36.25rem;
}

.wrap {
    padding-top: 9.31rem;
    padding-bottom: 3.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100%;
    background: var(--main);
}

@media only screen and (max-width: 640px) {
    .btn {
        margin-top: 3.31rem;
        width: 21.6875rem;
        height: 3.75rem;
    }
    .chat {
        margin-top: 2.56rem;
        width: 21.4375rem;
    }
    .img {
        margin-top: 3.25rem;
        &_1 {
            width: 21.4375rem;
            height: 14.375rem;
        }
        &_2 {
            margin-top: 2.81rem;
            width: 21.1375rem;
            height: auto;
        }
    }
    .message {
        &:nth-child(2) {
            text-align: right;
        }
        &+.message {
            margin-top: 0.75rem;
        }
    }
    .text {
        font-size: 1.5rem;
        line-height: 126.4%;
        max-width: 18.4rem;
    }
    .wrap {
        padding-top: 5.94rem;
        padding-bottom: 2.1rem;
    }
}